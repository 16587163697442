<template>
  <v-dialog width="20%" v-model="dialog" persistent scrollable>
    <v-card>
      <v-toolbar dark color="blue lighten-1" dense>
        <v-toolbar-title>Lists...</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="dialog = false" color="error">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-data-table
              fixed-header
              height="60vh"
              :headers="listDataTable.headers"
              :items="listDataTable.items"
              hide-default-footer
              disable-pagination
              no-data-text="Ma'lumotlar mavjud emas !"
              dense
            >
              <template #item.date="{ item: list }">
                {{ list.date.toString().replace("T00:00:00.000Z", " ") }}
              </template>
              <template #item.actions="{ item: list }">
                <v-btn icon @click="openListTextareaDialog(list)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog v-model="listTextarea.dialog" width="50%">
      <v-card>
        <v-toolbar color="blue lighten-1" dark dense>
          <v-toolbar-title>{{ listTextarea.date }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="listTextarea.dialog = false">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid>
          <v-textarea
            color="blue lighten-1"
            outlined
            style="font-family: Consolas"
            v-model="listTextarea.vModel"
            :height="(listTextarea.vModel.length / 57) * 35"
            hide-details
          />
        </v-container>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from "../../axios";

export default {
  name: "ShowListDialog",
  data: () => ({
    dialog: false,
    resource: {
      clientId: null,
    },
    listDataTable: {
      headers: [
        {
          text: "SANA",
          value: "date",
          divider: true,
          class: "blue lighten-1 white--text",
          align: "center",
        },
        {
          text: "AMALLAR",
          value: "actions",
          divider: false,
          class: "blue lighten-1 white--text",
          align: "center",
        },
      ],
      items: [],
    },
    listTextarea: {
      dialog: false,
      date: "",
      vModel: "",
    },
  }),
  methods: {
    async init(client) {
      this.resource.clientId = client.id;

      await this.fetchLists();
      this.dialog = true;
    },
    async fetchLists() {
      try {
        this.$overlay(true);

        const { data: lists } = await axios.get(
          `/lists/${this.resource.clientId}`
        );
        this.listDataTable.items = lists.data;
      } catch (error) {
        this.$toast.error(error?.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
    openListTextareaDialog(list) {
      this.listTextarea.vModel = list.list;
      this.listTextarea.date = list.date
        .toString()
        .replace("T00:00:00.000Z", " ");
      this.listTextarea.dialog = true;
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Consolas";
  src: url("../../../assets/fonts/consola.ttf") format("truetype");
}
.console {
  font-family: "Consolas" sans-serif !important;
}
</style>
