<template>
  <v-dialog width="40%" v-model="dialog" persistent scrollable>
    <v-card>
      <v-toolbar dark color="blue lighten-1" dense>
        <v-toolbar-title>Xabar yuborish uchun manzillar</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="dialog = false" color="error">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container>
        <v-data-table
          fixed-header
          height="60vh"
          :headers="locationDataTable.headers"
          :items="locationDataTable.items"
          :item-class="selected"
          hide-default-footer
          disable-pagination
          no-data-text="Ma'lumotlar mavjud emas !"
          @click:row="selectLocation"
          dense
        >
        </v-data-table>
      </v-container>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn small color="warning" @click="dialog = false" class="w-40"
          >Bekor qilish</v-btn
        >
        <v-btn
          small
          color="success"
          @click="save"
          class="w-40"
          :disabled="
            locationDataTable.selectedLocationId ==
            resource.client?.location?.id
          "
          >Saqlash</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "../../axios";

export default {
  name: "SelectRegionDialog",
  props: {
    afterSave: {
      type: Function,
      default: async () => {},
    },
  },
  data: () => ({
    dialog: false,
    resource: {
      client: {
        id: null,
        location: {
          id: null,
          islam: "",
          open_weather: "",
        },
      },
    },
    locationDataTable: {
      selectedLocationId: null,
      headers: [
        {
          text: "ID",
          value: "id",
          divider: true,
          class: "blue lighten-1 white--text",
          align: "center",
        },
        {
          text: "Namoz vaqtlari uchun",
          value: "islam",
          divider: true,
          class: "blue lighten-1 white--text",
          align: "center",
        },
        {
          text: "Ob-havo ma'lumoatlari uchun",
          value: "open_weather",
          divider: true,
          class: "blue lighten-1 white--text",
          align: "center",
        },
      ],
      items: [],
    },
  }),
  methods: {
    async openDialog(client) {
      this.resource.client = { ...client };

      await this.fetchLocations();

      this.dialog = true;
    },
    async fetchLocations() {
      if (this.locationDataTable.items.length) return;

      try {
        this.$overlay(true);

        const { data: locations } = await api.get("/locations/get-locations");
        this.locationDataTable.items = locations.data;
      } catch (error) {
        this.$toast.error(error?.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
    selectLocation(item) {
      this.locationDataTable.selectedLocationId = item.id;
      this.resource.client.location_id = item.id;
    },
    selected(item) {
      return item.id == this.resource.client.location_id
        ? "blue-grey lighten-4"
        : "";
    },
    async save() {
      try {
        this.$overlay(false);

        const { data } = await api.put(`/clients/${this.resource.client.id}`, {
          location_id: this.locationDataTable.selectedLocationId,
        });

        this.$toast.success(data.message || "Xodimning manzili o'zgartirildi!");
        await this.afterSave();
        this.dialog = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
  },
};
</script>
