<template>
  <v-container fluid>
    <v-row dense>
      <section-title>Фойдаланувчилар</section-title>
    </v-row>

    <v-divider class="light-blue lighten-8 mt-2 mb-2 elevation-10" />

    <v-row dense>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-text-field
          label="QIDIRUV ..."
          v-model="searchTextField.vModel"
          append-icon="mdi-magnify"
          @keydown.enter="fetchClients"
          hide-details
          @keydown.esc="clearSearchTextField"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          fixed-header
          height="70vh"
          :headers="clientDataTable.headers"
          :items="clientDataTable.data"
          item-key="id"
          no-data-text="Ma'lumotlar mavjud emas !"
          disable-filter
          disable-sort
          :footer-props="{
            itemsPerPageOptions: [10, 15, 20, 30, 40, 50],
            itemsPerPageText: 'Jadvaldagi qatorlar soni: ',
          }"
          :server-items-length="clientDataTable.meta.total"
          :options.sync="clientDataTable.options"
          dense
        >
          <template #item.phone_number="{ item: client }">
            {{
              client.phone_number
                ? client.phone_number.replace(
                    /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/,
                    "$1 ($2) $3 $4-$5"
                  )
                : ""
            }}
          </template>
          <template #item.active="{ item: client }">
            <v-icon :color="client.active ? 'success' : 'warning'"
              >mdi-checkbox-marked</v-icon
            >
          </template>
          <template #item.actions="{ item: client }">
            <v-btn icon @click="showListDialog(client)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn icon @click="openSelectLocationDialog(client)">
              <v-icon color="primary">mdi-map-marker-radius</v-icon>
            </v-btn>
            <v-btn
              icon
              color="error"
              @click="refreshClient(client)"
              v-if="client.active"
            >
              <v-icon color="error">mdi-exit-run</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <show-list-dialog ref="ShowListDialog" />
    <select-location-dialog
      ref="SelectLocationDialog"
      :after-save="fetchClients"
    />
  </v-container>
</template>

<script>
import axios from "../../axios";
import SectionTitle from "../../components/SectionTitle.vue";
import ShowListDialog from "./show-list-dialog.vue";
import SelectLocationDialog from "./select-location-dialog.vue";

export default {
  name: "Client",
  components: { ShowListDialog, SectionTitle, SelectLocationDialog },
  data: () => ({
    searchTextField: {
      vModel: null,
    },
    clientDataTable: {
      headers: [
        {
          text: "ACTIVE",
          value: "active",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "CHAT ID",
          value: "chat_id",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "USERNAME",
          value: "username",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "FIRST NAME",
          value: "first_name",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "LAST NAME",
          value: "last_name",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "FIO",
          value: "full_name",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "TELEFON RAQAM",
          value: "phone_number",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "RAQAM",
          value: "number",
          divider: true,
          class: "blue lighten-1 white--text",
        },
        {
          text: "AMALLAR",
          value: "actions",
          divider: false,
          class: "blue lighten-1 white--text",
        },
      ],
      data: [],
      meta: {
        total: 1,
      },
      options: {
        page: 1,
        itemsPerPage: 15,
      },
    },
  }),
  watch: {
    "clientDataTable.options": "fetchClients",
  },
  created() {
    this.fetchClients();
  },
  methods: {
    async fetchClients() {
      try {
        this.$overlay(true);

        const { data: clients } = await axios.get("/clients", {
          params: {
            page: this.clientDataTable.options.page,
            per_page: this.clientDataTable.options.itemsPerPage,
            search: this.searchTextField.vModel,
          },
        });
        this.clientDataTable.data = clients.data;
        this.clientDataTable.meta.total = clients.total;
      } catch (error) {
        this.$toast.error(error?.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
    async clearSearchTextField() {
      this.searchTextField.vModel = null;
      await this.fetchClients();
    },
    showListDialog(client) {
      this.$refs.ShowListDialog.init(client);
    },
    async deleteClient(client) {
      if (!confirm("Mijozni o'chirishga rozimisiz ?")) return;
      try {
        this.$overlay(true);

        const { data } = await axios.delete(`/clients/${client.id}`);

        await this.fetchClients();
        this.$toast.success(data.message || "");
      } catch (error) {
        this.$toast.error(error?.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
    async refreshClient(client) {
      if (!confirm("Mijozni botdan chiqarib yuborishga rozimisiz ?")) return;
      try {
        this.$overlay(true);

        const { data } = await axios.put(`/clients/${client.id}`, {
          active: false,
        });

        await this.fetchClients();
        this.$toast.success(data.message || "");
      } catch (error) {
        this.$toast.error(error?.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
    async openSelectLocationDialog(client) {
      await this.$refs.SelectLocationDialog.openDialog(client);
    },
  },
};
</script>
