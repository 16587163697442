<template>
  <div class="text-[30px] leading-8 font-black">
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
};
</script>
